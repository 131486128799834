import React from 'react';
import SEO from "../../components/seo/Seo";
import Header from "../../components/headers/Header";
import Footer from "../../components/layouts/Footer";
import CallToAction from "../../components/layouts/CallToAction";
import CuisineDescription from "../../components/cuisine/CuisineDescription";
import CuisineHeader from "../../components/cuisine/CuisineHeader";
import CuisineGoodToKnow from "../../components/cuisine/CuisineGoodToKnow";

function Kulinarika(props) {
    return (
        <div>
            <SEO title="Kulinarika" description="Obiskovalci Rudnika Sitarjevec Litija lahko ob obisku Izvoznega rova poskusijo tradicionalno »sitarjevško malco« ali pa »škratov šmorn«"/>
            <Header/>
            <CuisineHeader/>
            <CuisineDescription/>
            <CuisineGoodToKnow/>
            <CallToAction/>
            <Footer/>
        </div>
    );
}

export default Kulinarika;