import React from 'react';
import { FiCheck } from "react-icons/fi";
import { Link } from 'gatsby';
import {StaticImage} from "gatsby-plugin-image";

function CuisineGoodToKnow(props) {
    return (
        <div className="rn-about-area ptb--120 bg_color--3 text-center text-lg-left">
            <div className="container">
                <div className="row row--35 align-items-center">
                    <div className="col-lg-6 order-lg-1 order-0">
                        <div className="">
                            <div className="section-title">
                                <h2 className="title">Dobro je vedeti</h2>
                            </div>
                            <div className="mt--30">
                                <ul className="list-style--1">
                                    <li>
                                        <FiCheck />
                                        Ogled rudnika s malico je možen samo s predhodno najavo - <Link to="/kontakt-in-informacije">rezervacija.</Link>
                                    </li>

                                    <li>
                                        <FiCheck />
                                        Malico prilagodimo tudi za vegeterjance in vegane.
                                    </li>

                                    <li>
                                        <FiCheck />
                                        Ogled rudnika s malico traja uro in pol.
                                    </li>
                                    <li>
                                        <FiCheck />
                                        V rovu je stalna temperatura 10°C in 100% vlaga.
                                    </li>
                                </ul>
                            </div>

                            <div className="mt--40">
                                <Link to="/kontakt-in-informacije" className="btn-default size-md">Rezerviraj ogled</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default CuisineGoodToKnow;