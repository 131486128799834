import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

function CuisineDescription(props) {
    return (
        <>
            <div className="about-wrapper ptb--120">
                <div className="container">
                    <div className="row align-items-center text-center text-lg-left">
                        <div className="col-lg-6 col-md-12">
                            <div className="">
                                <div className="section-title">
                                    <h2 className="title">Sitarjevška malca in Škratov šmorn</h2>
                                    <p className="description">Po predhodnem naročilu (vsaj 10 oseb) lahko obiskovalci ob obisku Izvoznega rova
                                        poskusijo tradicionalno »sitarjevško malco« ali pa »škratov šmorn«. SITARJEVŠKA MALCA
                                        je sestavljena iz pogače z ocvirki, slanine v ajdovem testu, hrenovim mousseom na
                                        jabolčnem krhlju ter kompota iz sezonskega sadja.</p>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-xl-6 mt-4">
                            <div className="thumbnail">
                                <StaticImage
                                    src="../../assets/images/rudnik/sitarjevska-malca.jpg"
                                    alt="Sitarjevška malca in Škratov šmorn"
                                    placeholder="blurred"
                                    imgClassName="img-fluid w-100"
                                    loading="lazy"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CuisineDescription;